'use strict';

import 'babel-polyfill';
import Home from './pages/home';
import VideoList from './pages/videolist';
import Video from './pages/video';
import Event from './pages/event';
// import Blog from './pages/blog';
import Header from './modules/header';
import ImageGallery from './modules/imageGallery';
// import 'paginationjs';

class App {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.videotab = document.querySelector('.video-tab');
    // this.galleries = [...document.querySelectorAll('[data-gallery]')]; 
    // this.videoLists = [...document.querySelectorAll('.card-item')];
    this.videoLists = [...document.querySelectorAll('[data-multivideo-box]')];
    // this.registerBox = document.querySelector('#footer-cta-register');
    this.events();
    
  }

  events() {
    if(this.body.classList.contains("front_page")){
      new Home();
    }

    new Header();
    this.imageGallery = new ImageGallery();
    if(this.imageGallery.images)this.imageGallery.init();

    if(this.videoLists.length > 0) {
      // new VideoList(this.videoLists);
      this.videoLists.forEach(element => {
        new VideoList(element);
      });
    }

    if(this.videotab) {
      new Video();
    }

    let event = new Event();
    if(event.element) event.archives();
    if(event.elementDetail) event.detail();

    // let blog = new Blog();
    // if(blog.element) blog.archives();
    // if(blog.elementDetail) blog.detail();

    // if($('#pagination-container').length) {
    //   $('#pagination-container').pagination({
    //     dataSource: this.videoLists,
    //     pageSize: 5,
    //     pageRange: 1,
    //     autoHidePrevious: true,
    //     autoHideNext: true,
    //     hideWhenLessThanOnePage: true,
    //     prevText: '<i class="fas fa-angle-left"></i>',
    //     nextText: '<i class="fas fa-angle-right"></i>',
    //     callback: function(data, pagination) {
    //         // template method of yourself
    //         // var html = template(data);
    //         console.log(data);
    //         $('#data-container').html(data);
    //     }
    //   })
    // }
  }


  
}

window.App = new App();


