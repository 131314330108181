import { scrollTop } from '../helper/helpers';

export default class Header {

  constructor() {
    this.body = document.querySelector('body');
    this.element = document.querySelector('.header');
    this.container = document.querySelector('.header-nav');
    this.main = document.querySelector('.wrap');
    this.navItems = document.querySelectorAll('.header-nav nav a');
    this.mainIsInView = false;
    this.headerIsFixed = false;
    this.headerIsRemove = false;
    this.hamburger = document.querySelector('.navburger');
    this.mobileNavIsOpen = false;
    this.stp = 0;
    this.header = null;
    this.init();
  }

  init() {
    // this.onScroll();
    // this.scrollHandle = throttle(this.onScroll, 16).bind(this);
    window.addEventListener('scroll', this.onScroll.bind(this));
    if(this.hamburger)this.hamburger.addEventListener('click', this.hamburgerHandle.bind(this));
  }

  // static createInstance() {
  //   var object = new Header();
  //   return object;
  // }

  // static getInstance() {
  //   let object;
  //   if (!Header.header) {
  //     object = Header.createInstance();
  //     Header.header = object;
  //   }
  //   return Header.header;
  // }

  onScroll(e) {
    const st = scrollTop();
    if (this.main)this.mainIsInView = st > 250;
    this.headerIsFixed = this.element.classList.contains('fixed');

    if (this.mainIsInView && !this.headerIsFixed) {
      this.element.classList.add('fixed');
    } else if (!this.mainIsInView && this.headerIsFixed) {
      this.element.classList.remove('fixed');
    }

    // if (st > 0) {
    //   (st > this.stp) ? this.removeHeader() : this.defaultHeader();
    //   this.stp = scrollTop();
    // }
  }

  defaultHeader() {
    if (this.headerIsRemove) {
      this.element.classList.remove('remove');
      this.headerIsRemove = false;
    }
  }

  removeHeader() {
    if (!this.headerIsRemove) {
      this.element.classList.add('remove');
      this.headerIsRemove = true;
    }
  }

  hamburgerHandle() {
    this.mobileNavIsOpen ? this.closeMobileNav() : this.openMobileNav();
  }

  openMobileNav() {
    this.body.classList.add('mobile-nav-open');
    this.mobileNavIsOpen = true;
  }

  closeMobileNav() {
    this.body.classList.remove('mobile-nav-open');
    this.mobileNavIsOpen = false;
  }



}


