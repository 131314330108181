
import Cookies from 'js-cookie';
import SlideShow from '../modules/slideshow';

export default class Home {

  constructor() {

    this.body = document.querySelector('body');
    this.setCookies();
    new SlideShow();
  }

  setCookies() {
    let kingco = Cookies.get('king_alert');
    if(!kingco) {
      this.aler18pop();
    }    
  }

  aler18pop() {
    document.querySelector('html').classList.add('overlay-active');
    this.body.insertAdjacentHTML('beforeend', this.createPopup());
    this.linkAlertClose = document.querySelector('[data-alert-link]');
    this.overlay18 = document.querySelector('.overlay--18');
    this.linkAlertClose.addEventListener('click', this.closeAlert.bind(this));
  }

  closeAlert(e) {
    e.preventDefault();
    Cookies.set('king_alert', 'true', { expires: 1 });
    document.querySelector('html').classList.remove('overlay-active');
    // this.overlay18.remove();
    if (this.overlay18.parentNode) {
      this.overlay18.parentNode.removeChild(this.overlay18);
    }

  }

  createPopup() {
    const win18 = `
    <div class="overlay overlay--gray overlay--18 show">
        <div class="box-18">
            <h1><svg width="100%" height="100%" class="svg-logo">
            <use xlink:href="#icon-logo"></use>
            </svg></h1>
            <div class="box-18_caption-box">
                <img src="/html/template/default/assets/img/icon_18.png" alt=""  class="box-18_icon">
                <p>このサイトは成人向けの情報を含みますので、<br>
                18歳未満の方の閲覧を固くお断りいたします。</p>
            </div>
            <p class="box-18_caption">あなたは18歳以上ですか？</p>
            <div class="columns">
                <div class="column" rel="60">
                    <div class="btn btn-key">
                        <a class="link bold full" href="#" data-alert-link>はい（サイトへ移動します）</a>
                    </div>
                </div>
                <div class="column" rel="30">
                    <div class="btn">
                        <a class="link full" href="https://www.google.co.jp/">いいえ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
    return win18;
  }


}
