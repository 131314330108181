
// import Hls from 'hls.js';
import videojs from 'video.js';
export default class Video {

  constructor() {
    this.body = document.querySelector('body');
    this.tab = document.querySelector('.tab');
    this.tabnavs = [...this.tab.querySelectorAll('.tab__nav__item')];
    this.tabcontents = [...this.tab.querySelectorAll('.tab__content__item')];
    this.prevn = document.querySelector('[data-tab-nav="tab1"]');
    this.prev = document.querySelector('[data-tab-content="tab1"]');
    this.videos = [];
    this.events();
  }

  events() {

    this.tabnavs.forEach(element => {
      element.addEventListener('click', this.toggle.bind(this));
    });
    if(this.tabnavs.length > 0){
      this.tabnavs[0].classList.add('current');
    }
    if(this.tabcontents.length > 0){
      this.tabcontents[0].classList.add('current');
      this.initVideo();
    }
  }

  initVideo() {
    this.tabcontents.forEach(element => {
      let videoEle = element.querySelector('.video-js');
      // var videoSrc = videoEle.dataset.videoSrc;
      // if (Hls.isSupported()) {
      //   var hls = new Hls();
      //   hls.loadSource(videoSrc);
      //   hls.attachMedia(videoEle);
      // } else if (videoEle.canPlayType('application/vnd.apple.mpegurl')) {
      //   videoEle.src = videoSrc;
      // }
      // this.videos.push(videoEle);

      // var options = {
      //   nativeAudioTracks: false,
      //   nativeVideoTracks: false,
      //   vhs: {
      //     overrideNative: true
      //   }
      // };


      var hlsOptions = {
        "withCredentials": true,
        "overrideNative": true
      };
      var options = {
        "controls": true,
        "autoplay": false,
        "preload": "auto",
        "html5": {
          "nativeAudioTracks": false,
          "nativeVideoTracks": false,
          "vhs": hlsOptions
        },
        "flash": {
          "vhs": hlsOptions
        }
      };

      var player = videojs(videoEle.id, options);
      this.videos.push(player);

    });
  }

  toggle(e) {
    const target = e.currentTarget;
    const name = target.dataset.tabNav;
    const selector = `[data-tab-content="${name}"]`;
    const content = this.tab.querySelector(selector);
    let num = 0;
    if(this.prev){
      this.prev.classList.remove('current');
      this.prevn.classList.remove('current');
      num = this.prev.dataset.num - 1;
      this.videos[num].pause();
    }
    content.classList.add('current');
    target.classList.add('current');

    this.prev = content;
    this.prevn = target;
  }





}
