

export default class VideoList {

  constructor(list) {

    this.body = document.querySelector('body');
    this.btn = list;
    this.status = false;
    this.content = null;
    this.target = null;
    this.events();
    // this.setCookies();

  }

  events() {
    this.btn.addEventListener('click', this.toggle.bind(this));
    this.body.addEventListener('click', this.close.bind(this));
  }

  toggle(e) {
    e.stopPropagation();
    // e.preventDefault();
    this.target = e.currentTarget;
    // const selector = `#${name}`;
    this.content = this.target.querySelector('.multivideo-box');
    if(this.status){
      this.content.classList.remove('active');
      this.target.classList.remove('active');
      this.status = false;
    } else {
      this.content.classList.add('active');
      this.target.classList.add('active');
      this.status = true;
    }
  }

  close(e) {
    if(this.status){
      this.content.classList.remove('active');
      this.target.classList.remove('active');
      this.status = false;
    } 
  }


}
