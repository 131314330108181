
import 'whatwg-fetch';
import moment from 'moment';
export default class Event {

  constructor() {
    this.body = document.querySelector('body');
    this.element = document.querySelector('.event_page');
    this.elementDetail = document.querySelector('.event_page_detail');
    this.loading = document.querySelector('.contents-loading');
    
    this.requestURL = 'https://wp.kingdom.vc/wp-json/wp/v2/events';
    // this.tabnavs = [...this.tab.querySelectorAll('.tab__nav__item')];
  }

  archives() {

    this.event = document.querySelector('[data-event]');

    fetch(this.requestURL).then((response) => {
        if (response.ok) {
          return response.json();
        } 
      })
        .then(responseData => {
          this.loading.classList.remove('loading');
          this.archivesRendered(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  archivesRendered(data) {
    data.forEach(element => {
      let child = document.createElement("div");
      child.classList.add('card-item');
      let thum = (element.acf.thumbnail_image.url) ? element.acf.thumbnail_image.url : 'https://kingdom.vc/wordpress/wp-content/themes/kingdom/assets/img/event_bnr_ph.jpg';

      let m = moment(element.date);
      let output = m.format('YYYY年MM月DD日');

      child.innerHTML = `<a href="/user_data/event_detail?event_id=${element.id}" class="card-item__link">
      <img src="${thum}" alt="" class="product-thumb__img">
      <div class="product-thumb-meta">
        <h3 class="product-thumb-meta__title">${element.title.rendered}</h3>
        <p class="product-thumb-meta__act">${output}</p>
      </div>
      </a>`;
      this.event.appendChild(child);
    });
  }


  // ------------


  detail() {
    this.eventDetail = document.querySelector('[data-event-detail]');
    this.edTitle = document.querySelector('[data-event-detail="title"]');
    this.edImage = document.querySelector('[data-event-detail="image"]');
    this.edContents = document.querySelector('[data-event-detail="contents"]');
    var queryString = window.location.search;
    var queryObject = new Object();
    if(queryString){
      queryString = queryString.substring(1);
      var parameters = queryString.split('&');
    
      for (var i = 0; i < parameters.length; i++) {
        var element = parameters[i].split('=');
    
        var paramName = decodeURIComponent(element[0]);
        var paramValue = decodeURIComponent(element[1]);
    
        queryObject[paramName] = paramValue;
      }
    }

    if(!queryObject.event_id) {
      return false;
    }

    fetch(this.requestURL + '/' + queryObject.event_id).then((response) => {
      if (response.ok) {
        return response.json();
      } 
    })
      .then(responseData => {
        // console.log(responseData);
        this.loading.classList.remove('loading');
        this.detailRendered(responseData);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  }


  detailRendered(data) {
    this.edTitle.innerHTML = data.title.rendered;

    let mainimg = (data.acf.main_image.url) ? data.acf.main_image.url : 'https://kingdom.vc/wordpress/wp-content/themes/kingdom/assets/img/event_bnr_ph.jpg';

    this.edImage.innerHTML = `<img src="${mainimg}" alt="" class="">`;
    
    let detailcontents = '';
    data.acf.contents.forEach(element => {
      
      switch (element.acf_fc_layout) {
        case 'title_content':
          detailcontents += `<h3 class="section-title section-title--normal2 section-title--line">${element.text}</h3>`
          break;

        case 'table_content':
          detailcontents += `<div class="table">`
          element.tr.forEach(td => {
            detailcontents += `<div class="tr">
              <div class="th">${td.headline}</div>
              <div class="td">${this.tableContent(td.body)}</div>
              </div>`;
          });
          detailcontents += `</div>`
          break;
      }

    });

    this.edContents.innerHTML = detailcontents;

  }

  tableContent(body) {
    let contents = '';
    body.forEach(item => {
      switch (item.acf_fc_layout) {
        case 'text_area':
          contents = item.text;
          break;
  
        case 'list':
          contents += `<ul class="list">`;
          item.list.forEach(li => {
            contents += `<li>${li.text}</li>`;
          });
          contents += `</ul>`;
          break;
      }
    });

    // console.log(contents);
    return contents;
  }




}
