
import 'whatwg-fetch';
import Overlay from './overlay';
export default class ImageGallery {

    constructor() {
        this.images = [...document.querySelectorAll('[data-overlay]')]; 
    }

    init(){
        this.overlay = new Overlay();
        if(this.overlay.overlayBox){
            this.overlay.delegate(this.closeOverlay.bind(this));
        }
        if(this.images){
        this.images.forEach(item => {
            item.addEventListener('click', this.imageOverlay.bind(this));
        });
        }
    }

    imageOverlay(e) {
        e.preventDefault();
        const link = e.currentTarget;
        const resourceURI = link.href;
        this.overlay.noscrollOverlay();
        this.overlay.openContainerInOverlay();
        let img = document.createElement("img");
        img.setAttribute('class', 'img');
        this.overlay.overlayPage.appendChild(img);
        this.fetchContent(resourceURI);
    
    }

    fetchContent(resourceURI) {
        this.overlay.openShowOverlay();
        this.overlay.overlayBox.querySelector('.img').src = resourceURI;
        this.overlay.openContentInOverlay();
    }

    // fetchContent(resourceURI) {
    //     this.overlay.openShowOverlay();
    //     // var request = new Request(resourceURI);
    //     var request = resourceURI;
    //     fetch(request).then((response) => {
    //         if (response.ok) {
    //         // return Promise.resolve(response);
    //         return response;
    //         } 
    //     })
    //     .then(response => response.arrayBuffer())
    //     .then(buffer => {
    //         var base64Flag = 'data:image/jpeg;base64,';
    //         var imageStr = this.arrayBufferToBase64(buffer);
    //         this.overlay.overlayBox.querySelector('.img').src = base64Flag + imageStr;
    //         this.overlay.openContentInOverlay();
    //     });
    // }

    renderContent(page) {
        this.overlay.openContentInOverlay();
    }

    closeOverlay() {
        let content = document.querySelector('#ajaxPage');
        content.innerHTML = '';
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };
}
