
export default class Overlay {

    constructor() {
        this.overlayBox = document.querySelector('.overlay');
        this.overlayPage = document.querySelector('.overlayPage');
        if (this.overlayBox) {
            this.closeAnimationTime = 100;
            this.btnClose = this.overlayBox.querySelector('.btn-close');
            this.btnClose.addEventListener("click", this.closeHandler);
            this.closeHandler = this.close.bind(this);
            this.status = false;
            this.blackStat = false;
        }
    }

    delegate(closeFunc = null){
        this.delegateFunc = closeFunc;
    }

    noscrollOverlay() {
        document.querySelector('html').classList.add('overlay-active');
        this.status = true;
    }
    checkMiniWin(stat) {
        this.blackStat = stat;
        if (this.blackStat) {
            this.overlayBox.classList.add('overlay--black');
        }
    }
    openShowOverlay() {
        this.overlayBox.classList.add('show');
    }
    openContainerInOverlay() {
        this.overlayBox.classList.add('container__in');
    }
    openContentInOverlay() {
        this.overlayBox.classList.add('content__in');
        this.overlayBox.addEventListener("click", this.closeHandler);
    }
    topscroll() {
        this.overlayBox.scrollTop = 0;
    }

    close(e) {
        if (!e.target.classList.contains('overlay') && !e.target.classList.contains('btn-close') ) return false;
        this.overlayBox.classList.remove('content__in');
        setTimeout(() => {
            this.overlayBox.classList.remove('container__in');
        }, 200);
        setTimeout(() => {
            this.overlayBox.classList.remove('show');
            if (this.delegateFunc) this.delegateFunc();
        }, 400);
        setTimeout(() => {
            document.querySelector('html').classList.remove('overlay-active');
            this.overlayBox.removeEventListener("click", this.closeHandler);
            this.btnClose.removeEventListener("click", this.closeHandler);
            if (this.blackStat) {
                this.overlayBox.classList.remove('overlay--black');
                this.blackStat = false;
            }
            this.status = false;
        }, 600);
    }

    static staticClose() {
        this.overlayBox = document.querySelector('.overlay');
        let ol = new Overlay();
        if (this.overlayBox.classList.contains('show')) ol.close();
    }
}
