
import 'whatwg-fetch';
import Swiper from 'swiper';

export default class SlideShow {

    constructor() {
        this.coverSwiper = document.querySelector('.slideshow-container');
        this.coverSwiperWrap = document.querySelector('.slideshow-wrapper');
        this.carouselSwiper = document.querySelector('.carousel-container');
        // this.requestURL = 'http://ckingdom.local/wordpress/wp-json/wp/v2/slideshow/11';
        // this.requestURL = 'https://wp.kingdom.vc/wp-json/wp/v2/slideshow/11';
        this.requestURL = 'https://kingdom.vc/wp/wp-json/wp/v2/slideshow/11';
        this.fetchData();
        this.carouselSwiperHandle();
    }

    fetchData() {

        fetch(this.requestURL).then((response) => {
            if (response.ok) {
                return response.json();
            } 
        })
            .then(responseData => {
            this.getData(responseData);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    getData(data) {
      console.log(data);
        data.acf.slideshow.forEach(element => {
            let eventIcon = (element.is_event) ? '<img src="/html/template/default/assets/img/icon_event.svg" alt="" class="slideshow-slide__icon-event">' : '';
            let box = `<div class="slideshow-slide swiper-slide">`;
            box += `<a href="${element.url}">`;
            box += `${eventIcon}<div class="slideshow-slide__img"><img src="${element.image}" width="700" alt="" class="slideshow-slide__img__src"></div>`;
            box += `</a>`;
            box += `</div>`;
            this.coverSwiperWrap.innerHTML += box;
        });
        this.events();
    }

    events() {
        if (this.coverSwiper) {
          let imgSrcs = [...this.coverSwiper.querySelectorAll('.slideshow-slide__img__src')];
          let swiperCover = new Swiper(this.coverSwiper, {
            speed: 1000,
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 30,
            // preventClicks: false, 
            // preventClicksPropagation: false, 
            // virtualTranslate: false,
            loop: true,
            autoplay: {
              delay: 7000,
              waitForTransition: false,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
              bulletClass: 'pagination-bullet',
              bulletActiveClass: 'pagination-bullet-active',
              renderBullet: function (index, className) {
                return `<div class="${className}"><img src="${imgSrcs[index].src}"></div>`;
              }
            },
            breakpoints: {
              640: {
                spaceBetween: 20,
              }
            }
          });
        }
    }


    carouselSwiperHandle() {

        if (this.carouselSwiper) {
            let swiperCarousel = new Swiper(this.carouselSwiper, {
              speed: 700,
              slidesPerView: 7,
              spaceBetween: 35,
              // freeMode: true, 
              // centeredSlides: true,
              // virtualTranslate: false,
              // preventClicks: false, 
              // preventClicksPropagation: false, 
              loop: true,
              autoplay: {
                delay: 2500,
                // waitForTransition: false,
                // disableOnInteraction: false,
              },
              // observer: true,
              // observeParents: true,
              // onSlideChangeEnd : (s) => {s.fixLoop();},
              navigation: {
                nextEl: '.carousel-btn-next',
                prevEl: '.carousel-btn-prev',
              },
              breakpoints: {
                1200: {
                  slidesPerView: 6,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                }
              }
            });
        }
    }



}
